<template>
  <b-card title="Daikin - Manual Return Delivery">
    <b-col cols="12">
      <b-row>
        <b-col md="5">
          <label>Search by DO</label>
          <b-form-input
            
            v-model="search"
            type="search"
            placeholder="Enter DO"
            style="float: right; margin-bottom: 30px;"
          />
        </b-col>
        <b-col md="2">
          <h3 style="text-align: center ; margin-top: 20px; margin-bottom: 30px;">
            or
          </h3>
        </b-col>
        <b-col md="5">
          <label>Search by Barcode</label>
          <b-form-input
            
            v-model="searchBarcode"
            type="search"
            placeholder="Search by Barcode"
            style="float: right; margin-bottom: 30px;"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" v-if="permission.manualReturn" class="mb-2 hide-on-desktop">
          <b-button
            variant="success"
            @click="manualReturn()"
          >
            Add Manual Return
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="permission.manualReturn">
        <b-col cols="4" class="mb-2 hide-on-mobile">
          <b-button
            variant="success"
            @click="manualReturn()"
          >
            Add Manual Return
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import { userAccess, dateFormat } from "@/utils/utils";
export default {
  data() {
    let mobileViewQuery = window.matchMedia("(max-width: 761px)"); //check true or false width screen mobile view
    return {
      mobileViewQuery: mobileViewQuery, //all info matchMedia
      mobileView: mobileViewQuery.matches, // return true or false match screen moobile view
      sapDoManual:'',
      tableLoading: true,
      search: "",
      searchBarcode: "",
      selectData: "",
    };
  },
  computed: {
    deliveryOrders() {
      var tempDO = this.$store.getters["delivery/getDO"]
      return tempDO;
    },
    permission() {
      let result = userAccess(
        "Manual Return Delivery Daikin",
        "return_delivery_menu"
      );
      // console.log("Return Delivery Sumitomo",result)
      return result;
    },
  },
  created() {
    document.title = "Return Delivery | RSP";
  },
  mounted() {
    //temporary for detail dropdown button conditional by mobile view or desktop view
    this.mobileViewQuery.addListener(() => {
      this.mobileView = this.mobileViewQuery.matches;
    });

    // Saving Menu Setting on localstorage session so it still same even after reloading the page
    if (this.$session.has("perPageReturnDelivery")) {
      this.perPage = this.$session.get("perPageReturnDelivery");
    }
    if (this.$session.has("sortByReturnDelivery")) {
      this.sortBy = this.$session.get("sortByReturnDelivery");
    }
    if (this.$session.has("sortDescReturnDelivery")) {
      this.sortDesc = this.$session.get("sortDescReturnDelivery");
    }
    var isMobile = window.matchMedia("(max-width: 761px)").matches;
    console.log("mobile is ", isMobile);
    var queryAvailable = -1;
    var query;
    if (this.$route.query.status) {
      query = this.$route.query.status;
      queryAvailable = this.listStatus.findIndex((x) => {
        return x == query;
      });
    }
    console.log("queryavailable ", queryAvailable);
    if (queryAvailable >= 0) {
      if (isMobile) {
        this.btnForMobile(query);
        return;
      }
      this.deliveryStatus = query;
    } else {
      this.deliveryStatus = "Returned";
    }
    if (this.$route.query.sapDo) {
      this.search = this.$route.query.sapDo;
    }
  },
  watch: {
    // Taking the Menu Setting from localstorage session so the setting will be the same as before
    perPage(perPageNew) {
      this.$session.set("perPageReturnDelivery", perPageNew);
    },
    sortBy(sortByNew) {
      this.$session.set("sortByReturnDelivery", sortByNew);
    },
    sortDesc(sortDescNew) {
      this.$session.set("sortDescReturnDelivery", sortDescNew);
    },
    // stackedStatus(stackedStatusNew) {
    //   this.$session.set("stackedStatusWarehouseDistribution", stackedStatusNew)
    // }
  },
  methods: {
    ...mapActions({
      getDeliveryOrder: "delivery/getDeliveryOrder",
      updateDelivery: "delivery/updateDO",
    }),
    queryDelivery() {
      return new Promise((resolve,reject)=>{
        this.getDeliveryOrder({
          sapDo: this.search,
          barcode: this.searchBarcode,
        }).then((res)=>{
          resolve(res)
        }).catch((err)=>{
          reject(err)
        })
      })
      
    },
    async filtering(){
      this.queryDelivery();
    },
    // method untuk manual return dari add manual return
    async manualReturn(){
      if(this.search === '' && this.searchBarcode === ''){
        this.$bvToast.toast(`Please add Search by DO or Search by Barcode`, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return;
      }
      try{
        await this.queryDelivery();
      }catch(err){
        this.$bvToast.toast(`Error getting Manual Return Data, Please Try again`, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return;
      }
      
      var result = this.deliveryOrders[0];

      console.log("result", result);
      
      if(result){
        var forbiddenStatus = ['New', 'Edit Delivery', 'Edit in Progress', 'Cancel Delivery', 'Delivery Canceled', 'Scanning in Progress', 'Scanning Completed', 'Delivery in Progress']

        var returnStatusList = ["Waiting Acknowledgment", "Waiting Approval", "Approved" ]

        if(result.sapDo[0]=='9'){
          this.$bvToast.toast('DO number that starts with 9 is not eligible for Manual Return: '+result.sapDo, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return;
        }
        
        if(forbiddenStatus.includes(result.status)){
          this.$bvToast.toast(`DO number is not found, or DO is not eligible for Manual Return`, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return;
        }
        
        if(returnStatusList.includes(result.status)  || (result.manualReturnFlag > 0 && result.status != 'Return Completed')){
          this.$bvToast.toast(`There is a manual return created for ${result.sapDo}, you are not allowed to create multiple manual return before the previous return was completed`, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return;
        }

        if(result.status == "Return Completed" && !result.ftpReturnSent){
          this.$bvToast.toast(`Return DO  ${result.sapDo} hasn't finished sending FTP to docabin, please try again later`, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return;
        }

        if(result.status == 'Fulfilled' || result.status == 'Collected' || result.status == 'Return Completed' ){
          this.$bvToast.toast("DO is Ready To Manual Return", {
            title: "Success",
            variant: "success",
            solid: true,
          });

          this.$router.push({
            name: "warehouse-manual-return-delivery",
            params: { id: result._id, from: "manual-return-daikin",status:result.status },
          });
        } else {
            this.$bvToast.toast(`DO number is not found, or DO is not eligible for Manual Return`, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        }
      }
      if(!result) {
        this.$bvToast.toast(`DO number is not found, or DO is not eligible for Manual Return`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
      }

    },
  },
};
</script>
<style scope>
.hide-at-all {
  display: none !important;
}

/* For mobile phones: */
@media (max-width: 761px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/* For computer: */
@media (min-width: 761px) {
  .hide-on-desktop {
    display: none !important;
  }
  .show-on-desktop {
    display: inline !important;
  }
}
</style>
